import Lottie from "lottie-react";
import useAuth from "hooks/useAuth";
import styled from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, Flex, Heading, Text, useMatchBreakpointsContext, useWalletModal } from "@pancakeswap/uikit";
import { useTranslation } from "contexts/Localization";
import Container from "components/Layout/Container";
import { useWeb3React } from "@web3-react/core";

import SunburstSvg from "./SunburstSvg";
import rocket from "./Assets/Rocket.json";

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

const StyledSunburst = styled(SunburstSvg)`
  height: 350%;
  width: 350%;

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 400%;
    width: 400%;
  }
`;

const Wrapper = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const FloatingPancakesWrapper = styled(Container)`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    visibility: visible;
  }
`;

const TopLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
`;

const BottomRightImgWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const topLeftImage = {
  path: "/images/home/flying-pancakes/",
  attributes: [
    { src: "1-bottom", alt: "Pancake flying on the bottom" },
    { src: "1-left", alt: "Pancake flying on the left" },
    { src: "1-top", alt: "Pancake flying on the top" }
  ]
};

const bottomRightImage = {
  path: "/images/home/flying-pancakes/",
  attributes: [
    { src: "2-bottom", alt: "Pancake flying on the bottom" },
    { src: "2-top", alt: "Pancake flying on the top" },
    { src: "2-right", alt: "Pancake flying on the right" }
  ]
};

const Footer = () => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { isTablet, isDesktop } = useMatchBreakpointsContext();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(login, logout, t);
  const matches2 = useMediaQuery("(min-width:1140px)");
  const matches6 = useMediaQuery("(min-width:576px)");

  return (
    <div>
      <Wrapper>
        <div style={{ display: !matches2 ? "flex" : "none", justifyContent: "center", alignItems: "center" }}>
          <Lottie loop={true} animationData={rocket} style={{
            height: matches2 ? "85%" : "100%",
            width: matches2 ? "23%" : !matches2 && matches6 ? "50%" : "100%"
          }} />
        </div>
        <Heading mb="24px" scale="xl" color="white">
          {t("Start in seconds.")}
        </Heading>
        <Text textAlign="center" color="white" fontSize={"24px"}>
          {t("Connect your crypto wallet to start using the app in seconds.")}
        </Text>
        <Text mb="24px" color="white" fontSize={"24px"}>
          {t("No registration needed.")}
        </Text>
        <Button onClick={onPresentConnectModal} style={{ backgroundColor: "white", color: "#37aa3d" }}>
          Connect Wallet
        </Button>
      </Wrapper>
    </div>
  );
};

export default Footer;